import { deleteCookie, getCookie, setCookie } from "cookies-next";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { loginUser } from "../../../../api/auth";
import { USER_PROFILE_KEY } from "@/src/lib/utils/constants";
import { formatApiErrors } from "@/src/lib/utils/helpers";
import BaseInput from "../../ui/BaseInput";
import AuthLayout from "../../../../layout/AuthLayout";
import { useMutation } from "@tanstack/react-query";
import useCustomHookForm from "../../../../hooks/useCustomHookForm";
import { useFormUtil } from "../../../../hooks/useFormUtil";
import { loginSchema } from "../../../schema";

export default function LoginUser() {
  const router = useRouter();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useCustomHookForm(loginSchema);

  const [isLoading, setIsLoading] = React.useState(false);

  const { mutate } = useMutation({
    mutationFn: loginUser,
    onSuccess: (data) => {
      const userData = data?.data;
      setCookie("token", userData?.token);
      localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(userData));
      deleteCookie("email");
      setCookie("account_type", userData?.account_type);
      const nextUrl: any = getCookie("nextUrl");
      const invoice_reference: any = getCookie("invoice_reference");
      const isBusiness = userData?.account_type === "Business";
      if (invoice_reference) {
        deleteCookie("invoice_reference");
        const route = `/${
          isBusiness ? "business" : "talent"
        }/dashboard/invoices/received?reference=${invoice_reference}&`;
        router.push(route);
      }
      if (nextUrl) {
        const decodedUrl = decodeURIComponent(nextUrl);
        let route;
        if (isBusiness) {
          route = decodedUrl.replace("/talent", "/business");
        } else {
          route = decodedUrl.replace("/business", "/talent");
        }
        deleteCookie("nextUrl");
        router.push(route);
        router.reload();
      }
      const route = `/${isBusiness ? "business" : "talent"}/dashboard/${
        userData?.kyc_status === "Not submitted" ? "compliance" : ""
      }`;
      router.push(route);
      router.reload();
    },
    onError: (error: any) => {
      setIsLoading(false);
      const errorMessage = error?.response?.data?.message;
      if (errorMessage.includes("not verified")) {
        setCookie("email", getValues("email"));
        router.push("/auth/verify-email");
      }
      setDefaultErrors(formatApiErrors(error?.response?.data?.errors));
    },
    onSettled: () => {
      setIsLoading(false);
    }
  });

  const handleLogin = (data: any) => {
    setIsLoading(true);
    mutate(data);
  };

  const { defaultErrors, setDefaultErrors } = useFormUtil(loginSchema.fields);

  return (
    <AuthLayout>
      <div className="login-user">
        <form className="login-user__form" onSubmit={handleSubmit(handleLogin)}>
          <h3>Login to your account</h3>
          <BaseInput
            label="Email"
            placeholder="Enter Email"
            id="email"
            type="email"
            autoComplete="email"
            register={register}
            errors={errors}
            defaultErrors={defaultErrors["email"]}
          />
          <BaseInput
            label="Password"
            placeholder="Enter Password"
            id="password"
            type="password"
            autoComplete="current-password"
            register={register}
            errors={errors}
            defaultErrors={defaultErrors["password"]}
          />

          <Link href="/auth/forgot-password" passHref>
            <a className="base-btn--link login-user__form__forgot-password">
              Forgot Password
            </a>
          </Link>

          <button
            className="base-btn--primary login-user__form__login-button"
            data-loading={isLoading}
          >
            Login
          </button>
        </form>

        <p className="login-user__no-account-yet">
          Do not have an account yet?{" "}
          <Link href="/onboard">
            <a className="base-btn--link">Sign up</a>
          </Link>
        </p>
      </div>
    </AuthLayout>
  );
}
