import React from "react";
import LoginUser from "../../src/components/modules/auth/LoginUser";
import { GetServerSidePropsContext } from "next";
import { requireAuthentication } from "../../src/lib/utils/requireAuthentication";

export default function login() {
  return <LoginUser />;
}

export const getServerSideProps = requireAuthentication(
  async (context: GetServerSidePropsContext) => {
    return {
      props: {}
    };
  }
);
