import Image from "next/image";
// import Link from "next/link";
import React from "react";

export default function AuthLayout({ children }: any) {
  return (
    <div className="auth-layout">
      <div className="auth-layout__header">
        <Image
          src="/assets/images/logo.svg"
          alt="bloccpay-logo"
          height={32}
          width={203}
        />

        {/* <div className="base-tabs">
          <Link href="/onboard/business" passHref>
            <a className="base-tabs__tab">Business</a>
          </Link>
          <Link href="/onboard/talent" passHref>
            <a className="base-tabs__tab" data-active>
              Talent
            </a>
          </Link>
        </div> */}
      </div>

      <div className="fade-top">{children}</div>
    </div>
  );
}
