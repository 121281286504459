import { httpRequest } from ".";
import { Account, ResponseDataInterface } from "../model";
import {
  ForgotPassword,
  LoginFields,
  ResendEmail,
  ResetPassword,
  UserRegistrationFields,
  VerifyEmailFields
} from "../model/auth.model";
import { apiRoutes } from "../src/routes";
import { pathToUrl } from "../src/routes/router";

export const registerUser = async (
  payload: UserRegistrationFields
): Promise<ResponseDataInterface<any>> => {
  const data = await httpRequest<ResponseDataInterface<any>>({
    method: "post",
    url: pathToUrl(apiRoutes.register),
    data: payload
  });
  return data;
};

export const loginUser = async (
  payload: LoginFields
): Promise<ResponseDataInterface<Account>> => {
  const data = await httpRequest<ResponseDataInterface<Account>>({
    method: "post",
    url: pathToUrl(apiRoutes.login),
    data: payload
  });
  return data;
};

export const verifyEmail = async (
  payload: VerifyEmailFields
): Promise<ResponseDataInterface<any>> => {
  const data = await httpRequest<ResponseDataInterface<Account>>({
    method: "get",
    url: pathToUrl(apiRoutes.verifyEmail, { token: payload.token }),
    data: payload
  });
  return data;
};

export const resendEmail = async (
  payload: ResendEmail
): Promise<ResponseDataInterface<any>> => {
  const data = await httpRequest<ResponseDataInterface<Account>>({
    method: "post",
    url: pathToUrl(apiRoutes.resendToken),
    data: payload
  });
  return data;
};

export const forgotPassword = async (
  payload: ForgotPassword
): Promise<ResponseDataInterface<any>> => {
  const data = await httpRequest<ResponseDataInterface<any>>({
    method: "post",
    url: pathToUrl(apiRoutes.forgotPassword),
    data: payload
  });
  return data;
};

export const resetPassword = async (
  payload: ResetPassword
): Promise<ResponseDataInterface<any>> => {
  const data = await httpRequest<ResponseDataInterface<any>>({
    method: "patch",
    url: pathToUrl(apiRoutes.resetPassword),
    data: payload
  });
  return data;
};
